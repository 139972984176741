<template>
  <a-table
    :columns="columns"
    :data-source="items"
    :pagination="{ pageSize: 20, total: pages }"
    :scroll="{ x: true }"
    @change="updatePage($event)"
  >
    <template #name="{ text }">
      <a href="#">{{ text }}</a>
    </template>
    <template #customTitle>
      <span>
        <smile-outlined />
        Name
      </span>
    </template>
    <template #action="{ record }">
      <span>
        <a-button @click="$router.push('/orders/' + record.id)" type="primary"
          >View</a-button
        >
        <a-divider type="vertical" />
        <a-popconfirm
          title="Are you sure delete this order?"
          ok-text="Yes"
          cancel-text="No"
          @confirm="deleteOrder(record.id)"
        >
          <a-button type="danger">Delete</a-button>
        </a-popconfirm>
      </span>
    </template>
  </a-table>
</template>
<script>
import { SmileOutlined } from "@ant-design/icons-vue";
import { defineComponent } from "vue";
import api from "@/services/api";
import { Table, Button, Divider, Popconfirm } from "ant-design-vue";
const columns = [
  {
    dataIndex: "id",
  },
  {
    title: "First name",
    dataIndex: "firstName",
  },
  {
    title: "Last name",
    dataIndex: "lastName",
  },
  {
    title: "Email",
    dataIndex: "email",
    rowKey: "id",
  },
  {
    title: "Country",
    dataIndex: "country",
  },
  {
    title: "Actions",
    dataIndex: "id",
    slots: {
      customRender: "action",
    },
  },
];
export default defineComponent({
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    pages: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    return {
      columns,
    };
  },

  components: {
    SmileOutlined,
    "a-table": Table,
    "a-button": Button,
    "a-divider": Divider,
    "a-popconfirm": Popconfirm,
  },
  methods: {
    async deleteOrder(id) {
      try {
        await api.delete(`/api/orders/${id}`);
        this.$emit("delete");
      } catch {
        console.log("error while update");
      }
    },
    updatePage(page) {
      this.$emit("change", page);
    },
  },
});
</script>
