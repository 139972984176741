<template>
  <a-row
    type="flex"
    justify="center"
    align="center"
    style="
       {
        margin-top: 64px;
        height: 90vh;
      }
    "
  >
    <a-col :xl="{ span: 6 }" :md="{ span: 8 }" :sm="{ span: 12 }">
      <a-form
        layout="vertical"
        :model="formState"
        @finish="handleFinish"
        @finishFailed="handleFinishFailed"
      >
        <a-form-item>
          <a-input
            v-model:value="formState.username"
            placeholder="Login"
            size="large"
          >
            <template #prefix
              ><UserOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-input
            v-model:value="formState.password"
            type="password"
            placeholder="Password"
            size="large"
          >
            <template #prefix
              ><LockOutlined style="color: rgba(0, 0, 0, 0.25)"
            /></template>
          </a-input>
        </a-form-item>
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            :disabled="formState.user === '' || formState.password === ''"
            block
          >
            Log in
          </a-button>
        </a-form-item>
      </a-form>
    </a-col>
  </a-row>
</template>
<script>
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import api from "@/services/api";
import cookieService from "@/services/cookieService";
import { Row, Col, Form, Input, Button } from "ant-design-vue";
export default defineComponent({
  setup() {
    const formState = reactive({
      username: "",
      password: "",
    });
    const router = useRouter();
    const store = useStore();
    const handleFinish = async () => {
      const { username, password } = formState;
      const { data } = await api.post("/api/login", {
        username,
        password,
      });
      cookieService.setToken(data.token);
      await store.commit("setLogged");
      await router.push("/");
    };

    const handleFinishFailed = (errors) => {
      console.log(errors);
    };

    return {
      formState,
      handleFinish,
      handleFinishFailed,
    };
  },

  components: {
    UserOutlined,
    LockOutlined,
    "a-row": Row,
    "a-col": Col,
    "a-form": Form,
    "a-form-item": Form.Item,
    "a-input": Input,
    "a-button": Button,
  },
});
</script>
