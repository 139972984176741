import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import cookieService from "@/services/cookieService";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (cookieService.isLogged()) {
        next();
      } else next("/login");
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/orders",
    name: "Orders",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/Orders.vue"),
    beforeEnter: (to, from, next) => {
      if (cookieService.isLogged()) {
        next();
      } else next("/login");
    },
  },
  {
    path: "/orders/:id",
    name: "Order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/OrderPage"),
    beforeEnter: (to, from, next) => {
      if (cookieService.isLogged()) {
        next();
      } else next("/login");
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
