import { createStore } from "vuex";

export default createStore({
  state: {
    isLogged: false,
  },
  mutations: {
    setLogged(state) {
      state.isLogged = true;
    },
    setLogout(state) {
      state.isLogged = false;
    },
  },
  actions: {},
  modules: {},
});
