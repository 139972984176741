<template>
  <a-typography-title>Orders list</a-typography-title>
  <orders-table
    :items="orders"
    :pages="totalPages"
    @change="updatePage($event)"
    @delete="getOrders"
  />
</template>

<script>
import OrdersTable from "@/components/OrdersTable";
import api from "@/services/api";
import { Typography } from "ant-design-vue";
export default {
  name: "Orders",
  components: {
    OrdersTable,
    "a-typography-title": Typography.Title,
  },
  data() {
    return {
      orders: [],
      page: 0,
      totalPages: 1,
    };
  },
  methods: {
    async getOrders() {
      const { data } = await api.get(`/api/orders?page=${this.page}`);
      this.orders = data.results;
      this.totalPages = data.total;
    },
    updatePage(obj) {
      this.page = obj.current - 1;
      this.getOrders();
    },
  },
  mounted() {
    this.getOrders();
  },
};
</script>

<style scoped></style>
