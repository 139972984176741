import axios from "axios";
import cookieService from "@/services/cookieService";
const api = axios.create({
  baseURL: "https://api1.hodomasks.com",
  timeout: 3000,
});
api.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = cookieService.getToken();

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default api;
