import cookie from "js-cookie";

const setToken = (token) => {
  cookie.set("token", token, { expires: 7 });
};
const getToken = () => {
  return cookie.get("token");
};
const isLogged = () => {
  const token = cookie.get("token");
  return !!token;
};
const checkLogged = (store) => {
  const token = cookie.get("token");
  if (!token) {
    store.commit("setLogout");
  } else {
    store.commit("setLogged");
  }
};
export default { setToken, getToken, isLogged, checkLogged };
