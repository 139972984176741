<template>
  <a-typography-title>Order </a-typography-title>
  <a-space>
    <a-button @click="isEdit = !isEdit">
      <template v-if="!isEdit">Edit</template>
      <template v-else>Cancel edit</template>
    </a-button>
    <a-button v-if="isEdit" @click="updateOrder" type="primary"
      >Update</a-button
    >
  </a-space>
  <a-divider />
  <a-descriptions v-if="order" title="Order Info" layout="horizontal" bordered>
    <a-descriptions-item label="Order number" :span="3">
      {{ order.id }}
    </a-descriptions-item>
    <a-descriptions-item label="Created date" :span="3">
      {{ formattedDate(order.createdAt) }}
    </a-descriptions-item>
    <a-descriptions-item label="Updated date" :span="3">
      {{ formattedDate(order.updatedAt) }}
    </a-descriptions-item>
    <a-descriptions-item label="First name" :span="3">
      <span v-if="!isEdit">{{ order.firstName }}</span>
      <a-input
        v-if="isEdit"
        v-model:value="order.firstName"
        placeholder="First name"
      />
    </a-descriptions-item>
    <a-descriptions-item label="Last name" :span="3">
      <span v-if="!isEdit">{{ order.lastName }}</span>
      <a-input
        v-if="isEdit"
        v-model:value="order.lastName"
        placeholder="Last name"
      />
    </a-descriptions-item>
    <a-descriptions-item label="Email" :span="3">
      <span v-if="!isEdit">{{ order.email }}</span>
      <a-input v-if="isEdit" v-model:value="order.email" placeholder="Email" />
    </a-descriptions-item>
    <a-descriptions-item label="Phone" :span="3">
      <span v-if="!isEdit">{{ order.phone }}</span>
      <a-input v-if="isEdit" v-model:value="order.phone" placeholder="Phone" />
    </a-descriptions-item>
    <a-descriptions-item label="Address" :span="3">
      <span v-if="!isEdit"
        >({{ order.zip }}) {{ order.country }}, {{ order.city }},
        {{ order.address }}</span
      >
      <a-space>
        <a-input v-if="isEdit" v-model:value="order.zip" placeholder="ZIP" />
        <a-input
          v-if="isEdit"
          v-model:value="order.country"
          placeholder="Country"
        />
        <a-input v-if="isEdit" v-model:value="order.city" placeholder="City" />
        <a-input
          v-if="isEdit"
          v-model:value="order.address"
          placeholder="Address"
        />
      </a-space>
    </a-descriptions-item>
    <a-descriptions-item label="Pay id" :span="3">
      <span>{{ order.payId }}</span>
    </a-descriptions-item>
    <a-descriptions-item label="Total" :span="3">
      <span>${{ order.total }}</span>
    </a-descriptions-item>
    <a-descriptions-item label="Status" :span="3">
      <div v-if="!isEdit">
        <a-tag v-if="order.payStatus === 'COMPLETED'" color="green"
          >Payed</a-tag
        >
        <a-tag v-if="order.payStatus === 'PAYED'" color="green">Payed</a-tag>
        <a-tag v-if="order.payStatus === 'CREATED'" color="blue">Created</a-tag>
        <a-tag v-if="order.payStatus === 'CANCELED'" color="red"
          >Canceled</a-tag
        >
        <a-tag v-if="order.payStatus === 'SENT'" color="cyan">Sent</a-tag>
        <a-tag v-if="order.payStatus === 'DELIVERED'" color="purple"
          >Delivered</a-tag
        >
      </div>
      <a-select
        v-if="isEdit"
        v-model:value="order.payStatus"
        style="width: 120px"
        ref="select"
      >
        <a-select-option value="COMPLETED">Payed</a-select-option>
        <a-select-option value="CREATED">Created</a-select-option>
        <a-select-option value="CANCELED">Canceled</a-select-option>
        <a-select-option value="SENT">Sent</a-select-option>
        <a-select-option value="DELIVERED">Delivered</a-select-option>
      </a-select>
    </a-descriptions-item>
    <a-descriptions-item label="Products" :span="3">
      <div v-for="(product, idx) in order.products" :key="idx">
        <span><b>Name:</b> {{ product.name }}</span>
        <br />
        <span><b>Coat:</b> {{ product.coat }}</span>
        <br />
        <span><b>Size:</b> {{ product.size }}</span>
        <br />
        <span><b>Gift:</b> {{ product.gift_type }}</span>
      </div>
    </a-descriptions-item>
    <a-descriptions-item label="Notes" :span="3">
      <span v-if="!isEdit">{{ order.notes }}</span>
      <a-textarea
        v-if="isEdit"
        v-model:value="order.notes"
        placeholder="Notes"
        :rows="4"
      />
    </a-descriptions-item>
  </a-descriptions>
</template>

<script>
import api from "@/services/api";
import dayjs from "dayjs";
import {
  Typography,
  Space,
  Button,
  Divider,
  Descriptions,
  Input,
  Select,
  Tag,
} from "ant-design-vue";
export default {
  name: "OrderPage",
  components: {
    "a-typography-title": Typography.Title,
    "a-space": Space,
    "a-button": Button,
    "a-divider": Divider,
    "a-descriptions": Descriptions,
    "a-descriptions-item": Descriptions.Item,
    "a-input": Input,
    "a-select": Select,
    "a-select-option": Select.Option,
    "a-tag": Tag,
    "a-textarea": Input.TextArea,
  },
  data() {
    return {
      order: null,
      isEdit: false,
    };
  },
  methods: {
    async getOrder() {
      const { data } = await api.get(`/api/orders/${this.$route.params.id}`);
      this.order = data;
    },
    formattedDate(date) {
      return dayjs(date).format("DD/MM/YYYY HH:mm");
    },
    async updateOrder() {
      await api.put(`/api/orders/${this.$route.params.id}`, {
        first_name: this.order.firstName,
        last_name: this.order.lastName,
        ...this.order,
      });
      await this.getOrder();
      this.isEdit = false;
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<style></style>
