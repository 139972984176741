<template>
  <a-layout class="page">
    <a-layout-header v-if="isLogged">
      <a-menu
        theme="dark"
        mode="horizontal"
        v-model:selectedKeys="selectedKeys"
        :style="{ lineHeight: '64px' }"
      >
        <a-menu-item key="Home" @click="$router.push('/')">
          <HomeOutlined />
          <span>Home</span>
        </a-menu-item>
        <a-menu-item key="Orders" @click="$router.push('/orders')">
          <gold-outlined />
          <span>Orders</span>
        </a-menu-item>
      </a-menu>
    </a-layout-header>
    <a-layout-content
      :style="{
        margin: '0',
        padding: '24px',
        background: '#fff',
        minHeight: '280px',
        height: '100%',
      }"
    >
      <router-view />
    </a-layout-content>
  </a-layout>
</template>
<script>
import { GoldOutlined, HomeOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref } from "vue";
import { useStore, mapState } from "vuex";
import cookieService from "@/services/cookieService";
import { useRoute } from "vue-router";
import { Layout, Menu } from "ant-design-vue";
export default defineComponent({
  components: {
    GoldOutlined,
    HomeOutlined,
    "a-layout": Layout,
    "a-layout-content": Layout.Content,
    "a-layout-header": Layout.Header,
    "a-menu": Menu,
    "a-menu-item": Menu.Item,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const currentName = route.name;
    const selectedKeys = ref(currentName);
    cookieService.checkLogged(store);
    return {
      selectedKeys,
      collapsed: ref(false),
    };
  },
  computed: {
    ...mapState(["isLogged"]),
  },
});
</script>
<style lang="scss">
.page {
  min-height: 100vh !important;
}
.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}
.trigger:hover {
  color: #1890ff;
}

.logo {
  margin: 16px;
  text-align: center;
  width: 100px;
  height: 100%;
  img {
    width: auto;
    height: 100%;
  }
}

.site-layout .site-layout-background {
  background: #fff;
}
</style>
